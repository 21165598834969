import React, { useEffect, useState } from "react";
import FrootingAlops from "../../assets/image/comedians/Frooting_Alops.jpg";
import ToucanQuotesJson from "../../data/frooting-alops-quotes.json";


const TheCryptoJungle = () => {
  const allQuotes = ToucanQuotesJson;
  const [quotes, setQuotes] = useState([]);
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

  useEffect(() => {
    setQuotes(allQuotes);
  }, [allQuotes]);

  const handleQuoteClick = () => {
    setCurrentQuoteIndex((currentIndex) => (currentIndex + 1) % quotes.length);
  };

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="position-relative z-index-1 bg-squeeze pt-26 dark-mode-texts">
        <div className="container position-static">
          <div className="row position-relative align-items-center position-static">
            <div
              className="col-xxl-7 col-xl-8 col-lg-9 pt-lg-23 pb-lg-33 pb-md-28 pb-xs-26 pb-29 pt-md-20"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="500"
            >
              <div className="row">
                <div className="col-xxl-8 col-xl-6 col-md-6 col-sm-6">

                  <div className="text-primary font-size-5 font-weight-semibold mb-7">
                  <h3 className="text-black-2" style={{ width: 650}}>Frooting Alops The Insider</h3>
                  <p className="text-black-2"  style={{ fontSize: "28px", width: 650}}>Today Show? The Crypto Jungle</p>
                    <p className="text-black-2" style={{fontSize: "18px", width: 450}} dangerouslySetInnerHTML={{ __html: quotes[currentQuoteIndex] }}></p>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-6" onClick={handleQuoteClick}>

                  
                  <img src={FrootingAlops} alt="Frooting Alops" style={{borderRadius: 40, marginLeft: 120, width: "500px"}} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TheCryptoJungle;
