import React, { useEffect, useState } from "react";
import DimielTheHugger from "../../assets/image/comedians/Dimiel_Hugger.jpg";
import BearQuotesJson from "../../data/bear-dimiel-quotes.json";
import VoteButtons from "./Vote";


const Hero = () => {
  const allQuotes = BearQuotesJson;
  const [quotes, setQuotes] = useState([]);
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

  useEffect(() => {
    setQuotes(allQuotes);
  }, [allQuotes]);

  const handleQuoteClick = () => {
    setCurrentQuoteIndex((currentIndex) => (currentIndex + 1) % quotes.length);
  };

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="position-relative z-index-1 bg-squeeze pt-26 dark-mode-texts">
        <div className="container position-static">
          <div className="row position-relative align-items-center position-static">
            <div
              className="col-xxl-7 col-xl-8 col-lg-9 pt-lg-23 pb-lg-33 pb-md-28 pb-xs-26 pb-29 pt-md-20"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="500"
            >
              <div className="row">
                <div className="col-xxl-8 col-xl-6 col-md-6 col-sm-6">

                  <div className="text-primary font-size-7 font-weight-semibold mb-7">
                    +21 Stand up shows listed right now
                  </div>
                  <h1 className="font-size-11 mb-9 text-black-2">
                    Find the most hilarious AI comedians. Click each image profile.
                  </h1>
                  <div className="text-primary font-size-5 font-weight-semibold mb-7">
                  Ladies and gentlemen, welcome to the most electrifying comedy show in the digital universe! You're in for a treat tonight as we dive headfirst into the world of AI with our stand-up comedians. Feeling the vibes? 

                  </div>
                    <h4>
                      <a href="https://discord.com/invite/4mXg6QSyY8" className="font-size-7">
                        Join to the community at discord and become a creator
                      </a>
                    </h4>
                    <p className="font-size-5">
                    <br/>Subscribe to get access to exclusive experiences, behind the cameras, expansions, and premium content.
                  </p>
                </div>
                <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-6">
                  <h3 className="text-black-2" style={{ width: 650}}>Dimiel Granfa The Hugger</h3>  
                  <p className="text-black-2"  style={{ width: 650}}>Featured today? Scifi and web3 smart contracts</p>
                  <VoteButtons style={{ width: 650}}/>
                  <h5 className="text-black-2" style={{fontSize: "26px", width: 450}}>{quotes[currentQuoteIndex]}</h5>
                  <img src={DimielTheHugger} alt="Dimiel Granfa" style={{borderRadius: 40, width: "620px"}} onClick={handleQuoteClick}/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
