import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing2/Hero";
import TheCryptoJungle from "../sections/landing2/TheCryptoJungle";
import TheCoinLifter from "../sections/landing2/TheCoinLifter";
import TheInterestellarWarriors from "../sections/landing2/TheInterestellarWarriors";

const IndexPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          bgClass: "dynamic-sticky-bg",
        }}
      >
        <meta charset="utf-8" />
        <link rel="icon" href="https://standup.pulsarforge.io/static/dao-it-board-faca066ef64723bd57dafd8faa37a786.png" sizes="256x256" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="keywords" content="Stand Up, AI, Comedy, Shows, Virtual Characters"/>
        <meta name="description" content="Stand Up AI Shows, welcome to the most electrifying comedy show in the digital universe! You're in for a treat tonight as we dive headfirst into the world of AI with our stand-up comedians"/>
        <meta name="author" content="Pulsarforge" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Stand Up AI Shows " />
        <meta property="og:description" content="Stand Up AI Shows, welcome to the most electrifying comedy show in the digital universe! You're in for a treat tonight as we dive headfirst into the world of AI with our stand-up comedians"/>
        <meta property="og:image" content="https://standup.pulsarforge.io/static/dao-it-board-faca066ef64723bd57dafd8faa37a786.png" />
        <meta property="og:url" content="https://standup.pulsarforge.io/" />
        <meta property="og:author" content="Pulsarforge" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@pulsarforge" />
        <meta name="twitter:creator" content="@pulsarforge" />
        <meta name="twitter:image" content="https://standup.pulsarforge.io/static/dao-it-board-faca066ef64723bd57dafd8faa37a786.png" />
        <meta name="twitter:title" content="Stand Up AI Shows" />
        <meta name="twitter:description" content="Stand Up AI Shows, welcome to the most electrifying comedy show in the digital universe! You're in for a treat tonight as we dive headfirst into the world of AI with our stand-up comedians"/>
        <link rel="apple-touch-icon" href="https://standup.pulsarforge.io/static/dao-it-board-faca066ef64723bd57dafd8faa37a786.png" />
        <link rel="manifest" href="./manifest.json" />
        <Hero />
        <TheCryptoJungle />
        <TheCoinLifter />
        <TheInterestellarWarriors />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
