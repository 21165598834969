import React, { useEffect, useState } from 'react';

const VoteButtons = () => {
  const [votes, setVotes] = useState(0);

  const handleUpvote = async () => {
    try {
      const response = await fetch('/votes-dimiel', {
        method: 'POST',
      });

      if (response.ok) {
        const newVotes = await response.json();
        setVotes(newVotes);
      } else {
        console.error('Error upvoting:', response.statusText);
      }
    } catch (error) {
      console.error('Error upvoting:', error);
    }
  };
  const retrieveVoteCount = async () => {
    try {
      const response = await fetch('/votes-dimiel');

      if (response.ok) {
        const voteCount = await response.json();
        setVotes(voteCount);
      } else {
        console.error('Error retrieving vote count:', response.statusText);
      }
    } catch (error) {
      console.error('Error retrieving vote count:', error);
    }
  };

  // Use useEffect to retrieve the vote count when the component mounts
  useEffect(() => {
    retrieveVoteCount();
  }, []);
  return (
    <div>
      <button onClick={handleUpvote}>Upvote 👏😂 {votes}</button>
    </div>
  );
};

export default VoteButtons;