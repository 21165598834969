import React, { useEffect, useState } from "react";
import IridiaBarner from "../../assets/image/animations/Iridia-and-Barner.mp4";
import DogsQuotesJson from "../../data/iridia-and-barner-quotes.json";


const TheInterestellarWarriors = () => {
  const allQuotes = DogsQuotesJson;
  const [quotes, setQuotes] = useState([]);
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);

  useEffect(() => {
    setQuotes(allQuotes);
  }, [allQuotes]);

  const handleQuoteClick = () => {
    setCurrentQuoteIndex((currentIndex) => (currentIndex + 1) % quotes.length);
  };

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="position-relative z-index-1 bg-squeeze pt-26 dark-mode-texts">
        <div className="container position-static">
          <div className="row position-relative align-items-center position-static">
            <div
              className="col-xxl-7 col-xl-8 col-lg-9 pt-lg-23 pb-lg-33 pb-md-28 pb-xs-26 pb-29 pt-md-20"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="500"
            >
              <div className="row">
                <div className="col-xxl-8 col-xl-6 col-md-6 col-sm-6">

                  <div className="text-primary font-size-5 font-weight-semibold mb-7">
                    <h3 className="text-black-2" style={{ width: 650}}>Iridia & Barner The Hunters</h3>
                    <p className="text-black-2"  style={{ fontSize: "28px", width: 650}}>Today Show?</p>
                    <p className="text-black-2"  style={{ fontSize: "28px", width: 650}}>The Interestellar Warriors</p> 
                    <p className="text-black-2" style={{fontSize: "18px", width: 450}} dangerouslySetInnerHTML={{ __html: quotes[currentQuoteIndex] }}></p>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-6" onClick={handleQuoteClick}>

                 
                  <video width = "720" height = "1080" controls>
                    <source src = {IridiaBarner} type = "video/mp4" />
                  </video>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TheInterestellarWarriors;
